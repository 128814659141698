import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'



export default function NavBar(props) {
  const [data_, setData] = useState([{ org_name: "", media: [{}] }]);
  const {orgName} = useParams()
  console.log(orgName)

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
}
  clearCacheData();
  
  useEffect(() => {
    const getData = async () => {
      try {
        const settings = { headers: { TENANT: `${orgName}` } };
        const response = await fetch(
          "https://cma-python-api.scottiplaw.com/api/media/",
          settings
        );
        const data_ = await response.json();
        setData(data_);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
    

  }, []);
  console.log(data_);

  const bypassCache = Math.random() * 1000  //allow new logo to load after upload

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          
          <Link to={"/"+ orgName }>
            <img 
              className="logo"
              src={`${data_[0].logo_url}?${bypassCache}`}
              alt={`${orgName} logo`}
              style={{borderRadius: 5}}
            ></img>
          </Link>      
        </Toolbar>
      </AppBar>
    </Box>
  );
}


