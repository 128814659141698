import React, { Fragment, useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const LandingTopBar = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar> 
          </Toolbar>
        </AppBar>
      </Box>
    )
}



const OrganizationInput = () => {

  const [org_name, setOrg_name] = useState("")

  function handleChange(e) {
    
     setOrg_name(e.target.value)
    }
    console.log(org_name)

    const handleKeypress = e => {
      //it triggers by pressing the enter key
    if (e.key == 13) {
      handleClick();
    }
  };
    
    function handleClick(e) {
      e.preventDefault()
      console.log('click')
       window.location.href = "https://cma-client-portal.scottiplaw.com/"+org_name
    }

   

    return (
       <div className='landing-page'>
        <h3 style={{marginBottom: 30}}>Welcome to the Contract Portal</h3>
        <form onSubmit={handleClick}>
         <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        
         <TextField
          required
          id="outlined-required"
          label="Enter Organization Name"
          value={org_name}
          onChange={handleChange}
          onKeyDown={handleKeypress}
         
          
        />
        </Box>
        <Button variant="outlined"  type="submit" style={{marginTop: 10}}>
            Submit
        </Button>
        </form>

       </div>
    )

}
const LandingPage = () => {
return (
    <Fragment>
        <LandingTopBar />
        <OrganizationInput/>
    </Fragment>
)
}


export default LandingPage;