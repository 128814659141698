import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import NavBar from "../components/NavBar";
import { useParams } from 'react-router-dom'
var hostname = window.location.hostname;
// eslint-disable-next-line
hostname = hostname.replaceAll(".", "-");


export function DraftBanner() {
  return (
    <div
      style={{
        border: "1px solid red",
        backgroundColor: "red",
        width: "100%",
        margin: "auto",
        paddingInline: 15,
        color: "black",
        fontSize: 30,
        opacity: 0.78,
      }}
    >
      <h2>DRAFT</h2>
      <p>this is not legal</p>
    </div>
  );
}

const HomePage = () => {
  const [data_, setData] = useState([{ org_name: "", media: [{}] }]);
  const [loading, setLoading] = useState(false);
  const [latest_data, setLatest_Data] = useState([]);
  const {orgName} = useParams()
console.log(orgName)

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        const settings = { headers: { TENANT: `${orgName}` } };
        const response = await fetch(
          "https://cma-python-api.scottiplaw.com/api/media/",
          settings
        );
        const data_ = await response.json();
        setData(data_);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);
  console.log(data_);

  
  useEffect(() => {
    
    const getLatestData = async () => {
      try {
        const settings = { headers: { TENANT: `${orgName}` } };
        const response = await fetch(
          "https://cma-python-api.scottiplaw.com/api/media/latest",
          settings
        );
        const data_ = await response.json();
        setLatest_Data(data_);
      } catch (err) {
        console.log(err);
      }
    };
    getLatestData();
  }, []);

  console.log(latest_data)

  
  if (loading) {
    return (
      <div>
        <NavBar logo_url={data_[0].logo_url} />
      <div className="top-container" align="left">
        <h1 className="upper-case">{data_[0].org_name.replace(/-/g," ") + " Contracts"}</h1>
        {latest_data.map((item) => (
        (item.document_status === 'True')?
            <li key={item.id} style={{listStyle: 'none', marginBottom: -20}}>
            <Link to={`${item.id}`}><span className="cap" style={{fontWeight: 450, textTransform: 'capitalize'}}>{item.file_name}</span></Link>
          </li>
            :""
        ))}
      </div>
      </div>
    );
  } else {
    console.log("loading");
  }
};

export default HomePage;
