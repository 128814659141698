// import Container from '@mui/material/Container';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AgreementPage from './pages/AgreementPage';
import LandingPage from './pages/LandingPage';



function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<LandingPage/>} />
        <Route path='/:orgName' element={<HomePage />} />
        <Route path='/:orgName/:id' element={<AgreementPage />} />
      </Routes>
      
      

    </div>
  );
}

export default App;
