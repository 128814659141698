import React from "react";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Tooltip from '@mui/material/Tooltip';

import Paper from "@mui/material/Paper";

export function DraftBanner() {
  return (
    <div
      style={{
        backgroundColor: "red",
        margin: "auto",
        paddingInline: 15,
        color: "black",
        fontSize: 20,
        opacity: 0.78,
        userSelect:'none'
      }}
    >
      <h3>DRAFT</h3>
      <p>this is not legal</p>
    </div>
  );
}

const AgreementPage = () => {
  let { id } = useParams();
  const { orgName } = useParams();
  console.log(orgName)
  const [data_, setData] = useState([{ org_name: "", media: [{}] }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        const settings = { headers: { TENANT: `${orgName}` } };
        const response = await fetch(
          "https://cma-python-api.scottiplaw.com/api/media/",
          settings
        );
        const data_ = await response.json();
        setData(data_);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  console.log(data_)

  let name = "";
  let elStatus = "";
  let element = "";

  data_[0].media.forEach((item) => {
    if (item.id == id) {              
      element = item.content;
      elStatus = item.document_status;  //Status == 'True' means document is not in draft
      name = item.file_name;
    }
  });


  let fixed_name = name.replace(/-|\s/g," ")
  const bypassCache = Math.random() * 1000

  return (
    <div>
      <div style={{padding: 20}}>
      <Tooltip title="Home" >
        <Link to={"/"+ orgName }>
          <HomeRoundedIcon style={{color: "lightgrey"}} />
         </Link>
      </Tooltip>
      </div>
      <div style={{textAlign: "center", marginTop: 50, marginBottom: 50}}>
        <Tooltip title="Home" >
          <Link to={"/"+ orgName }>
            <img src={`${data_[0].logo_url}?${bypassCache}`} alt={`${orgName} logo`} style={{height: '15%', width: '15%', borderRadius: 5, userSelect:'none'}}/>
          </Link>
        </Tooltip>
      </div>
      <Paper elevation={6} style={{ padding: 100, marginInline: 140, marginBottom: 50 }}>
        {elStatus === 'False' ? <DraftBanner /> : ""}

        <div>
          <span dangerouslySetInnerHTML={{ __html: element }} style={{userSelect:'none'}} ></span>
          <p style={{marginTop: 30, textAlign: 'center', color:'grey', fontSize: 14, userSelect: 'none'}}>&copy; Scott & Scott, LLP</p>
        </div>
      </Paper>
    </div>
  );
};

export default AgreementPage;
